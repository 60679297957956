<template>
  <v-app-bar 
    app
    flat
    color="white"
    v-resize="onResize"
    v-visibility-change="onVisibilityChange"
  >
    <v-slide-y-reverse-transition mode="out-in">
      <v-btn 
        icon
      >
        <icon-base
          width="20"
          height="24"
          icon-name="hive"
          class="hive accent--text"
        >
          <brand-symbol />
        </icon-base>
      </v-btn>
    </v-slide-y-reverse-transition>
    <!-- <v-expand-x-transition mode="out-in">
      <icon-base
        v-if="breakpoint('sm')"
        width="126"
        height="26"
        icon-name="Mobees"
        class="logo ml-1 mr-8"
      >
        <brand-logo />
      </icon-base>
    </v-expand-x-transition> -->

    <brand-filter 
      :brands="brands"
      :selected="brand"
      :loading="loading"
      :report="view.report"
      :roles="user.roles"
      @create="createBrand"
      @manage="manageBrand"
      @select="selectBrand"
    />

    <v-tabs
      flat
      color="accent"
      background-color="transparent"
      active-class="font-weight-black"
      class="ml-1"
    >
      <v-tab
        v-for="(tab, key) in tabs"
        :key="'tab-'+key"
        :to="{ 'name': tab.path.name, params: getParams(tab.path.params) }"
        :disabled="tab.disabled"
      >
        <span v-if="breakpoint('xs')">
          {{ tab.title }}
        </span>
        <v-icon 
          v-else
          class=""
        >
          {{ icons[key] }}
        </v-icon>
      </v-tab>
    </v-tabs>

    <v-spacer />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!view.report&&hasAuthorization.invite"
          :loading="view.controller.invite.loading"
          fab
          icon
          color="grey lighten-1"
          small
          class="mx-1"
          v-bind="attrs"
          v-on="on"
          @click="managePeople(brand)"
        >
          <v-icon 
            dense 
            v-text="icons.invite" 
          />
        </v-btn>
      </template>
      Convidar Pessoas
    </v-tooltip>

    <notification-center
      v-if="!view.report"
      :visible="view.visible"
      :user="user"
    />

    <v-menu
      offset-y
      transition="fade-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="!view.report"
          icon
          fab
          small
          v-on="on"
          class="ml-1 mr-0"
        >
          <!-- <v-icon v-text="icons.mdiDotsVertical" /> -->
          <v-avatar 
            color="accent" 
            size="32"
          >
            <span class="white--text text-overline font-weight-medium">{{ user | initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list class="pt-0">
        <v-subheader class="d-flex pl-3 pr-3">
          <v-avatar 
            color="accent" 
            size="32"
          >
            <span class="white--text text-overline font-weight-medium">{{ user | initials }}</span>
          </v-avatar>
          <span class="text-overline pl-4">
            {{ name }}
          </span>
        </v-subheader>
        <v-divider class="mb-2" />
        <v-list-item
          v-if="hasEmail&&!admin"
          @click="manageUser()"
        >
          <v-list-item-title>Alterar meus dados</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-show="admin"
          @click="toggleAccess(brand)"
        >
          <v-list-item-title>Acessar como...</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="endSession"
        >
          <v-list-item-title>Sair da conta</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>

  #app .v-app-bar {
    /* box-shadow: 0px 4px 4px -11px rgba(0, 0, 0, 0.2), 0px 5px 5px -10px rgba(0, 0, 0, 0.14), 0px 6px 10px -6px rgba(0, 0, 0, 0.12); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    z-index: 10;
  }
  @media (orientation: landscape) {
    .v-app-bar.mobile-only {
      transform: translateY(-100%) !important;
      /* display: none; */
    }
  }
  @media (orientation: portrait) {
    .v-app-bar.mobile-only {
      transform: translateY(0) !important;
      /* display: block; */
    }
  }

  .v-app-bar .v-tab {
    min-width: auto;
  }

  .v-toolbar__content {
    max-height: 120px;
    width: 100%;
  }
  /* .v-app-bar {
    background: var(--mobees-black) !important;
    transform: translateY(calc(16px - 100%)) !important;

  }
  .v-app-bar.toggle {
    transform: translateY(0) !important;
  } */

  .app-title {
    max-width: 72%;
    z-index: 1;
    transition: transform .15s ease-out;
    will-change: transform;
    transform: 0;
  }
  .app-title.extended {
    max-width: 80%;
    transform: translateX(-48px);
  }

  .v-app-bar .hive {
    width: 24px;
    color: var(--mobees-black);
    /* animation: buzz 5s 2s cubic-bezier(0.990, 0.005, 0.100, 1.005) infinite; */
  }

  @-webkit-keyframes buzz {
    0%{ 
      color: var(--mobees-black);
      filter: drop-shadow(4px 0px 0px rgba(rgb(71, 108, 255), 0)); 
    }
    25%{ 
      color: var(--mobees-black);
      filter: drop-shadow(-4px 0px 4px rgba(rgb(71, 108, 255), 0.5)); 
    }
    50%{ 
      color: rgb(71, 108, 255); 
      filter: drop-shadow(0px 4px 4px rgba(rgb(71, 108, 255), 1)); 
    }
    75%{ 
      color: var(--mobees-black);
      filter: drop-shadow(-4px 0px 4px rgba(rgb(71, 108, 255), 0.5)); 
    }
    100% { 
      color: var(--mobees-black);
      filter: drop-shadow(4px 0px 0px rgba(rgb(71, 108, 255), 0)); 
    }
  }


  .v-app-bar .logo {
    max-height: 26px;
    -webkit-filter: invert(72%);
    filter: invert(72%);
  }

  .v-app-bar .logo, .v-app-bar .hive {
    opacity: .8;
  }
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }

  .v-app-bar .btn-new {
    top: 84px;
    left: 274px;
  }

</style>

<script>
  // Utilities
  import services from '@/services'
  import { get, sync } from 'vuex-pathify'
  import device from 'mobile-device-detect';

  // Icons
  import { 
    mdiMagnify, mdiPlus, mdiDotsVertical,
    mdiMapMarkerMultipleOutline,
    mdiAccountOutline,
    mdiImageOutline,
    mdiViewDashboard,
    mdiMenuDown,
    mdiCog,
    mdiAccountPlus
  } from '@mdi/js';

  export default {
    name: 'TopBar',

    props: {
      brands: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => null
      },
      admin: {
        type: Boolean,
        default: false
      },
      ghost: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        menu: mdiMenuDown,
        config: mdiCog,
        mdiMagnify, mdiPlus, mdiDotsVertical,
        buzzers: mdiMapMarkerMultipleOutline,
        drivers: mdiAccountOutline,
        campaigns: mdiImageOutline,
        dashboard: mdiViewDashboard,
        invite: mdiAccountPlus
      },
      view: {
        tabs: {
          dashboard: {
            title: 'Dashboard',
            disabled: false,
            path: {
              name: 'Dashboard',
              params: ['brand']
            }
          },
          campaigns: {
            title: 'Campanhas',
            disabled: false,
            path: {
              name: 'Campanhas',
              params: ['brand']
            }
          },
          // insights: {
          //   title: 'Insights',
          //   disabled: false,
          //   roles: [1,5],
          //   path: {
          //     name: 'Insights',
          //     params: ['brand']
          //   }
          // },
        },
        controller: {
          invite: {
            loading: false,
            active: true
          },
        },
        report: false,
        landscape: false,
        mobile: false,
        updated: false,
        loading: false,
        visible: true
      },
      logos: {},
      authorization: {
        invite: [1,5],
        manage: [1,5,17],
      }
    }),

    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
      BrandFilter: () => import('@/components/BrandFilter'),
      NotificationCenter: () => import('@/components/NotificationCenter'),
    },

    computed: {
      brand: sync('app/views@brand.selected'),
      views: sync('app/views'),
      main: sync('app/views@main'),
      campaigns: get('campaigns/items'),
      filters: get('dashboard/data@filters'),
      redirect: sync('app/redirect'),
      toast: sync('app/toast'),

      tabs () {
        const roles = this.user.roles;
        const tabs = _.pickBy(this.view.tabs, (t, k) => this.view.report ? k=='dashboard' : (!_.has(t, 'roles') || _.size(_.intersection(t.roles, roles))>0));
        return _.mapValues(tabs, t => {
          console.log(t.roles);
          t.disabled = (_.has(t, 'clearance') && this.hasClearance(t.clearance)==true ? false : _.has(t, 'disabled') && (t.disabled==true || (_.isArray(t.roles)&&_.size(_.intersection(t.roles, roles))==0)));
          return t;
        });
      },

      loggedIn () {
        return !_.isEmpty(this.brands);
      },

      name () {
        return _.isEmpty(this.user.name) ? this.user.username : _.words(this.user.name)[0];
      },

      hasEmail () {
        return _.indexOf(this.user.username, '@')>=0;
      },

      hasAuthorization () {
        const roles = this.user.roles;
        const authorization = this.authorization;
        const grants = _.mapValues(authorization, action => {
          return _.intersection(action, roles)>0;
        })
        return grants;
      },
    },

    watch: {
      $route: {
        immediate: true,
        handler (to, from) {
          if (_.has(from, 'params')&&(parseInt(to.params.brand)!=parseInt(from.params.brand))) this.view.updated = false;
          this.updateView(to);
        }
      },
      brands (brands, old) {
        if (_.size(old)==0) {
          this.updateView();
        }
      },
      updated (b) {
        this.view.updated = b;
      },
      loading (b) {
        this.view.loading = b;
        _.each(this.view.controller, (v,k) => {
          if (this.view.controller[k].active) {
            this.view.controller[k].loading = b;
            this.view.controller[k].active = b;
          }
        })
      },
    },

    filters: {
      initials (user) {
        return !_.isNil(user) && !_.isNil(user.username) ? user.username.substring(0,1) : '';
      },
    },

    methods: {
      ...services,

      hasClearance (brand) {
        return _.indexOf(this.user.brands, brand.toString())>=0;
      },

      getParams (params) {
        return _.reduce(params, (params, p) => {
          return {
            [p]: this.$route.params[p],
          }
        }, {});
      },

      updateView (route) {
        route = _.isNil(route) ? this.$route : route;
        if (this.loggedIn) {
          let brand = _.isNil(route.params.brand) ? null : parseInt(route.params.brand);
          console.log('brand', brand);
          if (_.isNil(brand)) {
            brand = _.isNil(this.brand) || !_.has(this.brands, this.brand) ? _.first(_.map(_.orderBy(this.brands, ['title'], ['asc']), 'id')) : this.brand;
            this.selectBrand(brand);
          }else if (!_.isNil(brand)&&parseInt(this.brand)!=brand) {
            this.brand = brand;
          }
          if (!_.has(this.brands[brand], 'advertisers')) {
            this.brand = brand;
            this.getBrand(brand);
          }
          this.view.report = (_.has(this.$route.query, 'report')&&this.$route.query.report==1);
          if (_.has(route.query, 'profile')) {
            this.manageUser(route.query.profile);
          }
        }
      },

      selectBrand (id) {
        this.brand = id;
        this.getBrand(id);
        // console.log('Brand selected', id, this.$route.name);
        setTimeout(() => {
          this.$router.push({
            name: _.isNil(this.$route.name) ? 'Overview' : this.$route.name,
            params: { brand: id, campaign: null, period: null }
          }).catch((error) => {
            console.warn(error);
          });
        }, 250);
      },

      manageBrand (id) {
        this.$emit('manage-brand', id);
      },

      managePeople (id) {
        this.view.controller.invite.active = true;
        this.$emit('manage-people', id);
      },

      createBrand (title) {
        this.$emit('create-brand', title);
      },

      getBrand (id) {
        this.view.loading = true;
        this.$emit('get-brand', id);
      },

      manageUser (b) {
        this.$emit('manage-user', b)
      },

      toggleAccess (id) {
        if (_.isNil(id)) {
          this.$emit('end-access');
        }else{
          this.$emit('toggle-access', true, id);
        }
      },

      navigateBack () {
        let path = this.$route.path.split('/');
        path.pop();
        if (_.indexOf(path, 'campaigns')>=0 && path.length==3) path.pop();
        this.$router.push(path.join('/'));
      },

      onResize () {
        this.view.width = window.innerWidth;
        this.view.height = window.innerHeight;
        this.view.landscape = this.view.width > this.view.height;
        // console.log('display update', this.view.mobile, this.view.landscape);
      },

      setDictionary () {
        _.each(this.view.tabs, (title,k) => {
          this.view.tabs[k].title = this.getDictionary(k);
        });
      },

      endSession () {
        if (this.ghost) {
          this.toggleAccess()
        }else{
          this.getout()
        }
      },

      onVisibilityChange (evt, hidden) {
        // check status
        this.view.visible = !hidden;
      }
    },

    mounted () {
      this.setDictionary();
      this.updateView(this.$route);
      this.view.mobile = device.isMobileOnly;
    },
    destroyed () {
    },
  }
</script>
